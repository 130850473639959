<template>
    <div id="produce-home">produce-home</div>
</template>
<script>
    export default {
        name: 'ProduceHome',
        data () {
            return {
                
            }
        },
        activated () {
            console.log('activated, ProduceHome');
        },
        methods: {
            
        }
    }
</script>
<style lang="less">
    @import './index.less';
</style>